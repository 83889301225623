/* eslint-disable import/extensions */
import * as React from 'react'
import { Link } from 'gatsby'

import { Container } from '@material-ui/core'
import IndexLayout from '../templates/IndexLayout'

const NotFoundPage = () => {
  return (
    <IndexLayout title="Not Found">
      <div style={{ height: '20vh' }} />
      <Container maxWidth="sm" style={{ textAlign: 'center' }}>
        <h1>404: Page not found.</h1>
        <p>
          <Link to="/">Return to home.</Link>
        </p>
      </Container>
    </IndexLayout>
  )
}

export default NotFoundPage
